
      try {
        window.BUI_BUILD_VERSION = window.BUI_BUILD_VERSION || {};
        window.BUI_BUILD_VERSION["eimos"] = window.BUI_BUILD_VERSION["eimos"] || {};
        window.BUI_BUILD_VERSION["eimos"].commitId = "236fcd5";
        window.BUI_BUILD_VERSION["eimos"].branchName = "HEAD";
        window.BUI_BUILD_VERSION["eimos"].version = "PDT_20240714";
        window.BUI_BUILD_VERSION["eimos"].date = "2024-9-27 17:15:55";
      } catch (e) {
        console.error('BuiBuildVersion error');
        console.error(e);
      }
    