import { random, sleep, urlIsEqual } from '@/utils';
import Store, { BaseStore } from 'zustand-store';
import { findMicroAppByPath } from '@/qiankun';
import { MicroApp, loadMicroApp } from 'qiankun';
import { microAppList } from '../qiankun/config';
import Cookies from 'js-cookie';
import { Deferred } from '@/utils/deferred';
import { log } from '@/infra/log';
import { cloneDeep } from 'lodash';
import { AppIsolate } from '@/infra/appIsolate';
import type { SetInfoProps } from '@bui/ai-assistant';
import { ChatMode } from '@bui/ai-assistant/dist/const';
import { AIAsisstantType } from '@/components/AIAssistant';
import EventEmitter from 'events';
import { usePermission } from '@bui/eimos-sdk';

/**
 * 乾坤页面 id
 */
type MicroPageId = string;
/**
 * 用于存放页面相关的配置
 */
export type MicroPageConfig = {
  id: MicroPageId;
  key?: MicroPageId;
  title: string;
  originRoute: {
    path: string;
  };
  realRoute: {
    title?: string;
    path: string;
  };
  active: boolean;
  closeAble: boolean;
  history: [];
  cachePaths: string[];
  container: string;
  loaded: boolean;
  // 打开页面的来源
  fromPage?: {
    title: string;
    path: string;
  };
  //上一个点开的页面
  preClickPage?: string;
};

export type OpenAIType = {
  cfg: any;
  type: ChatMode;
  getInitInfo?: () => any;
  handleInfo?: SetInfoProps[];
  defaultFn?: string;
  autoSend?: any;
  clear?: boolean;
  isAbortFile?: boolean;
};

/**
 * 页面的容器 dom id
 * @param microPageId micro page id
 */
const getContainerId = (microPageId: MicroPageId) => {
  return `APP_MANAGEMENT_PAGE_${microPageId}`;
};

const initPage = (url: string, title: string, closeAble = true, fromPage?: MicroPageConfig) => {
  const microPageId = random();
  const page: MicroPageConfig = {
    id: microPageId,
    title: title || 'EIMOS',
    originRoute: {
      path: url,
    },
    realRoute: {
      title: title || 'EIMOS',
      path: url,
    },
    active: true,
    closeAble,
    history: [],
    cachePaths: [url],
    container: getContainerId(microPageId),
    loaded: false,
    fromPage: fromPage
      ? {
          title: fromPage.title,
          path: fromPage.realRoute?.path,
        }
      : undefined,
    preClickPage: fromPage ? fromPage.id : undefined,
  };
  return page;
};

export const routePrefixReg = /^\/[\w-]+\/[\w-]+/;

export const STORE_TABS_KEY = 'EIMOS_APP_MANAGEMENT_TABS';
export const STORE_COLLECT_TABS_KEY = 'EIMOS_APP_MANAGEMENT_COLLECT_TABS';

export const isMicroApp = (path: string) => {
  return !!microAppList.some((item) => {
    return path.startsWith(item.activeRule as string);
  });
};

export const getCollectedPages = (): MicroPageConfig[] => {
  const str = localStorage.getItem(STORE_COLLECT_TABS_KEY);
  try {
    return JSON.parse(str || '[]');
  } catch (e) {
    return [];
  }
};

export const getRoute = (path: string) => {
  const route = isMicroApp(path) ? path.replace(routePrefixReg, '') : path;
  const [routeBaseUrl, routeQueryString] = route.split('?');
  console.log('getRoute 参数 route', routeBaseUrl, routeQueryString);
  const searchParams = new URLSearchParams(routeQueryString || '');
  const globalSerachParams = new URLSearchParams(window.location.search);
  // 添加一些 appisolate 相关的参数到子应用的路由上
  const instances = AppIsolate.getInstances();
  for (let i = 0; i < instances.length; i++) {
    const ins = instances[i];
    if (globalSerachParams.has(ins.isolateCode) && !searchParams.has(ins.isolateCode)) {
      searchParams.set(ins.isolateCode, globalSerachParams.get(ins.isolateCode)!);
    }
  }
  console.log('getRoute 参数 route size', searchParams, searchParams.size);
  return searchParams.size ? `${routeBaseUrl}?${searchParams}` : route;
};

const EXCLUDE_PATHS: string[] = [
  '',
  '/',
  '/pro',
  '/pro/',
  '/pro/portal',
  '/pro/cutler',
  '/pro/cutler/compose',
  '/pro/bi',
  '/pro/task',
  '/pro/usercenter',
  '/pro/timeline',
  '/pro/integrate',
  '/pro/indicator',
];

const getCachedTabs = () => {
  let tabs: MicroPageConfig[] = [];
  try {
    const cachedTabs = sessionStorage.getItem(STORE_TABS_KEY) || '';
    tabs = JSON.parse(cachedTabs);
    tabs.forEach((item) => (item.loaded = false));
  } catch {
    tabs = [];
  }
  return tabs;
};

const initPages = (): MicroPageConfig[] => {
  const tabs = getCachedTabs();
  if (!tabs.length) {
    let title = '工作台';
    let path = '/pro/portal/home';
    const closeAble = false;
    try {
      const initHome = Cookies.get('homePageConfig');
      if (initHome) {
        const initHomeConfig = JSON.parse(initHome);
        title = initHomeConfig?.name;
        path = initHomeConfig?.url;
      }
    } catch (err) {
      console.log('err', err);
    }
    const page = initPage(path, title, closeAble);
    return [page];
  }
  return tabs;
};

export const getCollectedPagesMap = () => {
  const collectedPages = getCollectedPages();
  return collectedPages.reduce((pre, item) => {
    pre[item.originRoute.path] = item;
    return pre;
  }, {} as Record<string, MicroPageConfig>);
};

export const collectPage = (page: MicroPageConfig) => {
  let collectedPages = getCollectedPages();
  collectedPages = collectedPages.filter((item) => {
    return item.originRoute.path !== page.originRoute.path;
  });
  collectedPages.push(page);
  localStorage.setItem(STORE_COLLECT_TABS_KEY, JSON.stringify(collectedPages));
};

export const uncollectPage = (page: MicroPageConfig) => {
  let collectedPages = getCollectedPages();
  collectedPages = collectedPages.filter((item) => {
    return item.originRoute.path !== page.originRoute.path;
  });
  localStorage.setItem(STORE_COLLECT_TABS_KEY, JSON.stringify(collectedPages));
};

export class MicroAppStore extends BaseStore<MicroAppStore> {
  static pageInstances: Map<MicroPageId, Deferred<MicroApp>> = new Map();
  static pageEventEmitterInstances: Map<MicroPageId, EventEmitter> = new Map();
  instancesMap: Record<string, boolean> = {};
  static pageInsToName: Map<MicroPageId, string> = new Map();
  pages: MicroPageConfig[] = initPages();
  AIAsisstant: AIAsisstantType = {
    open: false,
    type: ChatMode.EIMOS,
    cfg: '{}',
  };
  static navigate: (url: string) => void = () => {
    //
  };
  async open(url: string, title: string) {
    console.log('open page', url, title);
    if (url.startsWith('/appstudio')) {
      url = url.replace('/appstudio', '');
    }
    if (EXCLUDE_PATHS.includes(url)) {
      console.error('链接错误', url);
      return;
    }
    if (!url.startsWith('/pro')) {
      console.error('链接请以/pro开头', url);
      url = `/pro${url}`;
    }

    // 如果当前浏览器 url 与此 url 不同则增加
    if (!location.href.endsWith(url)) {
      MicroAppStore.navigate(url);
    }

    const pages = this.get().pages;
    const isExistPage = pages.find((item) => {
      return urlIsEqual(item.originRoute.path, url);
    });
    if (isExistPage) {
      const currentPage = this.get().pages.find((item) => item.active)!;
      this.set((state) => {
        state.pages.forEach((item) => {
          if (urlIsEqual(item.originRoute.path, url)) {
            item.active = true;
            item.preClickPage = currentPage.id;
          } else {
            item.active = false;
          }
        });
      });
      this.isolateStyle();
    } else {
      const activePage = this.getActivePage();
      const pageConfig = initPage(url, title, true, cloneDeep(activePage));
      // //限制最多打开14个
      // const closedPages = this.get().pages.slice(0, -13);
      // const resPages = this.get().pages.slice(-13); // 从索引0开始删除到目标元素的位置（不包括目标元素）
      // this.set((state) => {
      //   state.pages = resPages;
      // });
      // closedPages.forEach((item) => {
      //   this.unmountMicroApp(item);
      // });
      this.set((state) => {
        state.pages.forEach((item) => {
          item.active = false;
        });
        state.pages.push(pageConfig);
      });
      this.isolateStyle();
      console.log('调用时机', 1, url, JSON.parse(JSON.stringify(pageConfig, null, 2)));
      await this.createApp(url, pageConfig);
    }
  }

  initPageIds() {
    this.set((state) => {
      state.pages = state.pages.map((item) => {
        const microPageId = random();
        return {
          ...item,
          key: item.key || item.id,
          id: microPageId,
          container: `APP_MANAGEMENT_PAGE_${microPageId}`,
        };
      });
    });
  }

  async setInitTab(fullPath?: string) {
    console.log('initTab', fullPath);
    fullPath = (fullPath || window.location.pathname + window.location.search).replace(
      '/appstudio',
      '',
    );
    if (EXCLUDE_PATHS.includes(fullPath)) return;
    console.log('所有tab', JSON.parse(JSON.stringify(this.get().pages, null, 2)));
    let currentTab = this.get().pages.find((tab) => {
      return tab.cachePaths.some((p) => {
        return urlIsEqual(p, fullPath!);
      });
    });

    if (currentTab) {
      console.log('存在 current', JSON.parse(JSON.stringify(currentTab, null, 2)));

      this.set((state) => {
        state.pages.forEach((tab) => {
          tab.active = tab.id === currentTab!.id;
        });
        for (let i = 0; i < state.pages.length; i++) {
          const tab = state.pages[i];
          const isCurrentTab = tab.cachePaths.some((p) => {
            return urlIsEqual(p, fullPath!);
          });
          if (isCurrentTab) {
            console.log('改写了 _currentTab');
            if (tab.realRoute) {
              tab.realRoute = {
                ...tab.realRoute,
                path: fullPath!,
              };
              tab.realRoute.path = fullPath!;
              console.log('该写完的realRoute1', tab.realRoute.path);
            }
            break;
          }
        }
      });
      console.log('改写完的 tabs2', JSON.parse(JSON.stringify(this.get().pages, null, 2)));
      currentTab = this.get().pages.find((tab) => {
        return tab.cachePaths.some((p) => {
          return urlIsEqual(p, fullPath!);
        })!;
      });
    } else {
      console.log('不存在 current 初始化 currentTab');
      currentTab = initPage(fullPath, '');
      this.set((state) => {
        state.pages.forEach((tab) => {
          tab.active = false;
        });
        state.pages.push(currentTab!);
      });
    }
    this.isolateStyle();
    console.log('调用时机', 2, fullPath, JSON.parse(JSON.stringify(currentTab, null, 2)));
    await this.createApp(fullPath, currentTab!);
    this.initLcp(currentTab!.title, fullPath);
  }
  setRealRoute(fullPath: string) {
    const pages = this.get().pages;
    if (!pages || !pages.length) return;
    // if (!this.currentTabHasChanged()) {

    // }
    // 路由修改，但还是在当前页签内,将新路由赋给active为true的
    this.set((state) => {
      state.pages.forEach((page) => {
        if (page.active) {
          page.cachePaths = [...new Set([...page.cachePaths, fullPath])];
          page.realRoute = {
            path: fullPath,
          };
        }
      });
    });
  }

  onTabActive(page: Pick<MicroPageConfig, 'id'>) {
    const emitter = MicroAppStore.pageEventEmitterInstances.get(page.id);
    const ins = MicroAppStore.pageInstances.get(page.id);
    if (ins && emitter) {
      log.info('ontabactive', page);
      emitter.emit('tabActive', page);
    }
  }

  async switchPage(page: MicroPageConfig) {
    const activePage = this.get().pages.find((item) => item.active)!;

    if (activePage && page.id === activePage.id) {
      return;
    }

    this.set((state) => {
      state.pages.forEach((item) => {
        if (page.id === item.id) {
          item.preClickPage = activePage?.id;
          item.active = true;
        } else {
          item.active = false;
        }
      });
    });

    this.isolateStyle();

    MicroAppStore.navigate(page.realRoute.path);

    this.initLcp(page.title, page.realRoute.path);

    // document.trigger(new CustomEvent({type: '', data: {path: }}))
    // await this.refreshData(page);
  }

  async getInstance(pageId: MicroPageId) {
    return await MicroAppStore.pageInstances.get(pageId)?.promise;
  }

  initLcp(title: string, url: string) {
    if (this.get().AIAsisstant.type !== ChatMode.EIMOS) return;

    // ai助手针对应用进行初始化
    const regexEdit = /\/lcp\/(task|vm)\/\w+\/edit/;
    const regexCreate = /\/lcp\/(task|vm)\/\w+\/create/;
    let type = 'other';
    if (regexEdit.test(url)) type = 'edit';
    else if (regexCreate.test(url)) type = 'create';
    const cfg = this.get().AIAsisstant;
    this.updateOpenAI({
      open: cfg.open,
      type: cfg.type,
      cfg: JSON.stringify({
        app_name: title,
        app_id: url,
        app_type: type,
      }),
    });
  }
  async unmountMicroApp(page: MicroPageConfig) {
    const pageInstance = await this.getInstance(page.id);
    window.eimos?.utils.deletePageIns(page.id);
    this.deleteInstance(page.id);
    if (page.realRoute.path.includes('/workPlatForm')) {
      return;
    }
    if (pageInstance) {
      const container = document.getElementById(page.container);
      if (container) {
        // 获取 qiankun-head 元素
        const qiankunHeadElement = container.querySelector('qiankun-head');

        // 检查元素是否存在
        if (qiankunHeadElement) {
          qiankunHeadElement.innerHTML = '';
          // 获取父元素
          const parentElement = qiankunHeadElement.parentElement;

          // 检查父元素是否存在
          if (parentElement) {
            // 从父元素中移除 qiankun-head 元素
            parentElement.removeChild(qiankunHeadElement);
          }
        }
      }
      await pageInstance.unmount();
      // 兼容 lcp 弹窗中的 qiankun 关闭时再打开报错问题，提示元素找不到，所以下面这个 dom 就不销毁了
      // this.set((state) => {
      //   delete state.instancesMap[page.id];
      // });
      log.info('子应用卸载', '子应用卸载成功');
      // if (pageInstance.getStatus() === 'MOUNTED') {
      //   //
      // } else {
      //   console.error('子应用当前无法卸载', pageInstance.getStatus(), page);
      // }

      // this.set((state) => {
      //   delete state.instancesMap[page.id];
      // });
      // .then(() => {
      //   this.set((state) => {
      //     delete state.instancesMap[page.id];
      //   });
      //   console.log('eimos: 子应用卸载成功');
      // })
      // .finally(() => {
      //   this.set((state) => {
      //     delete state.instancesMap[page.id];
      //   });
      //   // console.log('eimos: 子应用卸载失败', e);
      // });
    }
  }

  /**
   * 清空页面
   */
  clearPages() {
    const cachedTabs = getCachedTabs();
    const newCachedTabs = cachedTabs.filter((item) => !item.active);
    sessionStorage.setItem(STORE_TABS_KEY, JSON.stringify(newCachedTabs));
    this.closeOtherPages();
  }

  closeOtherPages(page?: MicroPageConfig) {
    const activePage = (page || this.getActivePage()) as MicroPageConfig;
    const otherPages = this.get().pages.filter((item) => item.id !== activePage.id);
    //不能关闭的仍保留
    const openPages = otherPages.filter((item) => item.closeAble === false);
    //不能关闭的不能直接关闭
    const closedPages = otherPages.filter((item) => item.closeAble !== false);
    this.set((state) => {
      state.pages = [...openPages, activePage];
    });
    closedPages.forEach((item) => {
      this.unmountMicroApp(item);
    });
    // window.requestIdleCallback(() => {
    // });
  }

  closeLeftPages(page: MicroPageConfig) {
    const index = this.get().pages.indexOf(page);
    if (index !== -1) {
      const leftPages = this.get().pages.slice(0, index);
      const closedPages = leftPages.filter((item) => item.closeAble !== false);
      //左侧要活下来的页面
      const leftRespages = leftPages.filter((item) => item.closeAble === false);
      const resPages = this.get().pages.slice(index); // 从索引0开始删除到目标元素的位置（不包括目标元素）
      const openPages = [...leftRespages, ...resPages];
      this.set((state) => {
        state.pages = openPages;
      });
      closedPages.forEach((item) => {
        this.unmountMicroApp(item);
      });
      // window.requestIdleCallback(() => {
      // });
    }
  }

  closeRightPages(page: MicroPageConfig) {
    const index = this.get().pages.indexOf(page);
    if (index !== -1) {
      const rightPages = this.get().pages.slice(index + 1);
      const closedPages = rightPages.filter((item) => item.closeAble !== false);
      //右侧要活下来的页面
      const rightRespages = rightPages.filter((item) => item.closeAble === false);
      const resPages = this.get().pages.slice(0, index + 1); // 从索引0开始删除到目标元素的位置（不包括目标元素）
      const openPages = [...resPages, ...rightRespages];
      this.set((state) => {
        state.pages = openPages;
      });
      closedPages.forEach((item) => {
        this.unmountMicroApp(item);
      });
      // window.requestIdleCallback(() => {
      // });
    }
  }

  unmountAllPageIns() {
    const pages = this.get().pages;
    pages.forEach((item) => {
      this.unmountMicroApp(item);
    });
  }

  async refresh(pageId?: MicroPageId) {
    const current = this.getActivePage();
    const page = this.get().pages.find((item) => {
      if (pageId) {
        return item.id === pageId;
      } else {
        return item.id === current?.id;
      }
    });
    if (page) {
      await this.refreshPage(page);
    }
  }

  async refreshPage(page: MicroPageConfig) {
    const microPageId = random();
    this.set((state) => {
      state.pages.forEach((item) => {
        if (item.id === page.id) {
          item.id = microPageId;
          item.container = `APP_MANAGEMENT_PAGE_${microPageId}`;
          // 为了保证动画不被触发，这里保持 key 不变
          item.key = page.key || page.id;
        }
      });
    });
    const newPage = this.get().pages.find((item) => item.id === microPageId)!;
    try {
      this.unmountMicroApp(page);
      log.info('refreshPage', '刷新后卸载旧应用成功');
    } catch (e) {
      log.error('refreshPage', '刷新后卸载旧应用失败', e);
    }
    console.log(
      '调用时机',
      3,
      newPage.realRoute.path,
      JSON.parse(JSON.stringify(newPage, null, 2)),
    );
    return await this.createApp(newPage.realRoute.path, newPage);
  }

  async closePage(page: MicroPageConfig) {
    if (this.get().pages.length <= 1) {
      return;
    }
    const currentIndex = this.get().pages.findIndex((item) => {
      return item.id === page.id;
    });

    this.unmountMicroApp(page);

    //找到preClickPage是我的页面，他继承我的preClickPage  可能有多个
    const nextPages = this.get().pages.filter((item) => item?.preClickPage === page.id);
    nextPages.forEach((nextPage) => {
      this.set((state) => {
        const index = state.pages.findIndex((item) => item.id === nextPage.id);
        state.pages[index].preClickPage = page?.preClickPage;
      });
    });

    if (page.active) {
      this.set((state) => {
        state.pages.splice(currentIndex, 1);
        const fromIndex = state.pages.findIndex((item) => item.id === page?.preClickPage);
        const activeIndex =
          fromIndex > -1 ? fromIndex : Math.min(state.pages.length - 1, currentIndex);
        state.pages[activeIndex].active = true;
      });
      this.isolateStyle();
      const currentPage = this.get().pages.find((item) => item.active)!;
      const path = currentPage.realRoute?.path;
      console.log('调用时机', 4, path, JSON.parse(JSON.stringify(currentPage, null, 2)));
      await this.createApp(path, currentPage);
      MicroAppStore.navigate(path);
    } else {
      this.set((state) => {
        state.pages.splice(currentIndex, 1);
      });
      this.isolateStyle();
    }
  }

  async refreshData(page: MicroPageConfig) {
    console.log('调用时机', 5, page.realRoute.path, JSON.parse(JSON.stringify(page, null, 2)));
    const appDeferred = await this.createApp(page.realRoute.path, page);
    if (!appDeferred) {
      return;
    }
    await this.updatePathById(page.id, page.realRoute.path);
  }

  /**
   * 强制刷新当前激活的微应用
   */
  async forceUpdatePath(fullPath: string) {
    const activePage = this.getActivePage();
    if (activePage) {
      const pageId = activePage.id;
      await this.updatePathById(pageId, fullPath, true);
    }
  }

  async updatePathById(pageId: MicroPageId | undefined, fullPath: string, forceUpdate?: boolean) {
    if (!pageId) {
      return;
    }
    const app = await this.getInstance(pageId);
    const pageConfig = this.get().pages.find((item) => item.id === pageId);
    if (app?.getStatus() === 'MOUNTED') {
      if (pageConfig?.realRoute.path !== fullPath || forceUpdate) {
        log.info('perf', '路由变更，触发微应用更新', fullPath);
        this.setRealRoute(fullPath);
        const microAppPath = getRoute(fullPath);
        app?.update?.({ fullPath: microAppPath });
      } else {
        log.info('perf', '路由相同，不触发微应用更新', fullPath);
      }
    }
  }

  updateActiveTabById(id: string) {
    this.set((state) => {
      state.pages.forEach((item) => {
        item.active = id === item.id;
      });
    });
    this.isolateStyle();
  }

  async createApp(fullPath: string, pageConfig: MicroPageConfig) {
    console.log('创建应用', fullPath, JSON.parse(JSON.stringify(pageConfig, null, 2)));
    this.updateInstanceMap(pageConfig.id);
    if (MicroAppStore.pageInstances.has(pageConfig.id)) {
      return MicroAppStore.pageInstances.get(pageConfig.id)!;
    }
    const deferred = new Deferred<MicroApp>();
    MicroAppStore.pageInstances.set(pageConfig.id, deferred);
    await sleep(50);
    const pageIns = await this.createIns(fullPath, pageConfig);
    if (!pageIns) {
      return;
    }
    this.addInstance(pageConfig.id, deferred);
    await pageIns.bootstrapPromise;
    deferred.resolve(pageIns);
    return deferred;
  }

  private updateInstanceMap(pageId: MicroPageId) {
    this.set((state) => {
      if (!(pageId in state.instancesMap)) {
        state.instancesMap[pageId] = true;
      }
    });
  }

  private addInstance(id: MicroPageId, pageIns: Deferred<MicroApp>) {
    MicroAppStore.pageInstances.set(id, pageIns);
    this.updateInstanceMap(id);
  }

  deleteInstance(id: MicroPageId) {
    MicroAppStore.pageInstances.delete(id);
    const emitter = MicroAppStore.pageEventEmitterInstances.get(id);
    if (emitter) {
      emitter.removeAllListeners();
    }
    MicroAppStore.pageEventEmitterInstances.delete(id);
    // todo(ljw) 暂时保留容器 div
    // this.set((state) => {
    //   delete state.instancesMap[id];
    // });
  }

  cacheTabs() {
    const val = this.get().pages;
    sessionStorage.setItem(STORE_TABS_KEY, JSON.stringify(val));
  }

  updateCurTabTitle(title: string, path: string) {
    this.initLcp(title, path || '');
    if (!title) {
      return;
    }
    const curTab = this.get().pages.find((item) => item.active);
    if (curTab) {
      if (curTab.cachePaths.includes(path)) {
        this.updateTabTitle(curTab, title);
      }
    }
  }

  updateTabTitle(page: MicroPageConfig, title: string) {
    this.set((state) => {
      const p = state.pages.find((item) => item.id === page.id);
      if (p && p.title !== title) {
        p.title = title;
      }
    });
  }

  updateOpenAI({
    open,
    type,
    cfg,
    getInitInfo,
    handleInfo,
    defaultFn,
    autoSend,
    clear,
    isAbortFile,
  }: {
    open: boolean;
    type?: ChatMode;
    /**
     * 对于lcp描述了app_name、app_id和app_type
     */
    cfg?: string;
    getInitInfo?: () => any;
    handleInfo?: any[];
    defaultFn?: string;
    autoSend?: any;
    clear?: boolean;
    isAbortFile?: boolean;
  }) {
    this.set((state) => {
      state.AIAsisstant = {
        ...state.AIAsisstant,
        open,
        type: type || state.AIAsisstant.type,
        cfg: cfg || state.AIAsisstant.cfg,
        getInitInfo,
        handleInfo,
        defaultFn,
        autoSend,
        clear,
        isAbortFile,
      };
    });
  }

  updateAIMode(type: ChatMode) {
    this.set((state) => {
      state.AIAsisstant.type = type;
    });
  }

  private async createIns(fullPath: string, pageConfig: MicroPageConfig) {
    console.log('createIns', fullPath, pageConfig);
    const path = fullPath || pageConfig.originRoute.path;
    const route = getRoute(path);
    let microAppConfig = findMicroAppByPath(path);
    if (!document.getElementById(pageConfig.container)) {
      return;
    }

    MicroAppStore.pageInsToName.set(pageConfig.id, microAppConfig.name);

    const containerParent = document.getElementById(pageConfig.container)!;
    containerParent.classList.add('eimos-is-loading');
    const container = containerParent.querySelector('.eimos-page-inner')! as HTMLElement;
    const genLoading = () => {
      const div = document.createElement('div');
      div.setAttribute('data-name', 'skeleton-loading');
      div.setAttribute(
        'style',
        `position: absolute;
        z-index: 999;
        
        left: 0; top: 0; 
        right: 0;
        width: 100%; 
        padding: 20px; 
        background-color: #fff;
        `,
      );
      div.innerHTML = `
        <div class="ant-skeleton ant-skeleton-active" style="margin-bottom: 20px; max-width: 1220px;">
          <div class="ant-skeleton-content">
            <h3 class="ant-skeleton-title" style="width: 38%;"></h3>
            <ul class="ant-skeleton-paragraph"><li></li><li></li><li style="width: 61%;"></li></ul></div></div><div class="ant-skeleton ant-skeleton-active"><div class="ant-skeleton-content"><h3 class="ant-skeleton-title" style="width: 38%;"></h3><ul class="ant-skeleton-paragraph"><li></li><li></li><li style="width: 61%;"></li></ul></div>
        </div>
        
        `;
      return div;
    };
    const generateTabLoading = () => {
      const dom = containerParent && containerParent.querySelector('[data-name="tab-loading"]');
      if (dom) {
        return dom;
      }
      const div = document.createElement('div');
      div.setAttribute('data-name', 'tab-loading');
      div.setAttribute(
        'style',
        `position: absolute;
          z-index: 999;
          left: 0; top: 0; 
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%; 
          padding: 20px; 
          background-color: rgba(19, 30, 41, 0.6);
          overflow: hidden;
        `,
      );
      div.innerHTML = `
        <div class="tab-loading-circle tab-loading-animation1"></div>
        <div class="tab-loading-circle tab-loading-animation2"></div>
        <div class="tab-loading-circle tab-loading-animation3"></div>
        `;
      containerParent && containerParent.appendChild(div);
      return div;
    };
    const closeTabLoading = () => {
      try {
        containerParent.querySelector('[data-name="tab-loading"]')?.remove();
      } catch (e) {
        //
      }
    };
    const loading = genLoading();
    containerParent.appendChild(loading);
    const closeLoading = () => {
      try {
        containerParent.classList.remove('eimos-is-loading');
        containerParent.removeChild(loading);
      } catch (e) {
        //
      }
    };

    const emitter = new EventEmitter();
    MicroAppStore.pageEventEmitterInstances.set(pageConfig.id, emitter);
    let changeTabTitleTimer: NodeJS.Timeout;
    const hasPermission = usePermission.getState().hasPermission(path);
    if (!hasPermission) {
      microAppConfig = findMicroAppByPath('/pro/eimos');
    }
    console.log('hasPermission', hasPermission);

    const pageIns = loadMicroApp(
      {
        ...microAppConfig,
        container,
        name: pageConfig.id,
        props: {
          hasPermission,
          pageConfig: {
            route,
          },
          emitter,
          fromPage: pageConfig.fromPage,
          IS_TAB_MICRO_APP: true,
          closeMicroAppTab: () => {
            const page = this.get().pages.find((item) => item.id === pageConfig.id);
            if (page) {
              this.closePage(page);
            }
          },
          closeLoading: () => {
            closeLoading();
          },
          goBack: () => {
            const fromPage = pageConfig.fromPage;
            if (fromPage) {
              this.get().open(fromPage.path, fromPage.title);
            } else {
              log.warn('goBack tab', '不存在可返回的tab');
            }
          },
          goBackAndRefresh: async () => {
            const fromPage = pageConfig.fromPage;
            if (fromPage) {
              await this.get().open(fromPage.path, fromPage.title);
              const activePage = this.get().getActivePage();
              if (activePage) {
                await this.get().refreshPage(activePage);
              }
            } else {
              log.warn('goBack tab', '不存在可返回的tab');
            }
          },
          closeTab: () => {
            const page = this.get().pages.find((item) => item.id === pageConfig.id);
            if (page) {
              this.closePage(page);
            }
          },
          changeTabTitle: (title: string | ((preTitle: string) => string)) => {
            clearTimeout(changeTabTitleTimer);
            changeTabTitleTimer = setTimeout(() => {
              const page = this.get().pages.find((item) => item.id === pageConfig.id);
              if (page?.title === title) {
                return;
              }
              if (page) {
                const _title = typeof title === 'function' ? title(page.title) : title;
                this.updateTabTitle(page, _title);
                this.initLcp(_title, page?.realRoute.path || '');
              }
            }, 500);
          },
          openTab: (params: { title?: string; url: string }) => {
            this.open(params.url, params.title || '');
          },
          refreshTab: async (pageId?: MicroPageId) => {
            const page = this.get().pages.find((item) => {
              if (pageId) {
                return item.id === pageId;
              } else {
                return item.id === pageConfig.id;
              }
            });
            if (page) {
              await this.refreshPage(page);
            }
          },
          getCurTab: () => {
            const page = this.get().pages.find((item) => item.id === pageConfig.id);
            return page?.id;
          },
          openAI: (parmas: OpenAIType) => {
            this.updateOpenAI({
              open: true,
              type: parmas?.type,
              cfg: parmas?.cfg,
              getInitInfo: parmas?.getInitInfo,
              handleInfo: parmas?.handleInfo,
              defaultFn: parmas?.defaultFn,
              autoSend: parmas?.autoSend,
              clear: parmas?.clear,
              isAbortFile: parmas?.isAbortFile,
            });
          },
          generateTabLoading,
          closeTabLoading,
        },
      },
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fetch: async (url, ...args) => {
          const staticFiles = [
            '/static/bui/css/1.0.0/buifont.css',
            '/static/bui/lcp_assets/lcp-pro/1.0.0/lcp-pro.min.css',
            '/static/bui/css/1.0.0/common/all.buifont-buiMin-0.0.0.1.css',
            '/static/bui/css/1.0.0/bui.min.css',
            '/static/js/lodash/4.6.1/lodash.min.js',
            '/static/js/moment/2.29.4/min/moment.min.js',
            // '/static/js/qiankun/2.10.12/qiankun.min.js'
            '/static/js/prop-types/15.7.2/prop-types.js',
            '/static/js/react@17.0.2/umd/react.production.min.js',
            '/static/js/react@17.0.2/umd/react.development.js',
            '/static/js/react-dom@17.0.2/umd/react-dom.development.js',
            '/static/js/react@17.0.2/umd/react.development.min.js',
            '/static/js/react-dom@17.0.2/umd/react-dom.production.min.js',
            '/static/js/react-dom@17.0.2/umd/react-dom.development.min.js',
            '/static/js/icons/4.7.0/dist/index.umd.min.js',
            '/static/js/antd/4.24.7/dist/antd.min.js',
            '/static/js/antd/4.24.7/dist/antd.js',
            // '/static/bui/lcp_assets/lcp-pro/1.0.0/lcp-pro.min.js',
            '/static/js/common/all.min-react-reactDom-antd-proptype-qiankun-index_umd-momentjs-0.0.1.js',
            '/static/bui/css/1.0.0/tailwind.min.css',
            // '/lcp-asset/lcp-pro.min.js',
            // '/lcp-asset/lcp-pro.min.css',
            '/static/js/jsonata/1.8.7/jsonata.min.js',
          ];
          const isStaticFile = staticFiles.some((file) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const path = (url || '').split('?')[0];
            return path?.endsWith(file);
          });
          //
          if (isStaticFile) {
            return {
              async text() {
                return '';
              },
            };
          }

          if ((url as string)?.includes('lcp-pro')) {
            const TIME_KEY = 'lcp:lastFetchTime';
            const randomId = process.env.RANDOM_ID || '';
            localStorage.setItem(TIME_KEY, `${randomId}`);
            return window.fetch(`${url}?_t=${randomId}`, ...args);
          }

          return window.fetch(url as any, ...args);
        },
        excludeAssetFilter(url: string) {
          if (url && url.endsWith('.css')) {
            // console.log('%cqiankun exclude 的css ' + url, 'color: red; font-weight: bold;');
            return true;
          }
          return false;
        },
        $$cacheLifecycleByAppName: false,
      },
    );
    console.time('乾坤处理耗时');
    pageIns.loadPromise
      .then(() => {
        log.info('loadMicro', 'loadPromise', pageIns.getStatus());
      })
      .catch((e) => {
        closeLoading();
        log.error('loadMicro', 'loadPromise', pageIns.getStatus(), e);
      });
    pageIns.bootstrapPromise
      .then(() => {
        log.info('loadMicro', 'bootstrapPromise', pageIns.getStatus());
      })
      .catch((e) => {
        closeLoading();
        log.error('loadMicro', 'bootstrapPromise', pageIns.getStatus(), e);
      });
    pageIns.mountPromise
      .then(() => {
        if (!location.href.includes('/lcp')) {
          closeLoading();
        }
        log.info('loadMicro', 'mountPromise', pageIns.getStatus());
      })
      .catch((e) => {
        closeLoading();
        log.error('loadMicro', 'mountPromise', pageIns.getStatus(), e);
      });

    log.warn('loadMicro', 'status', pageIns.getStatus());
    return pageIns;
  }

  public getActivePage() {
    return this.get().pages.find((item) => item.active);
  }

  public getActivePageContainer() {
    const activePage = this.getActivePage();
    if (activePage) {
      const containerId = getContainerId(activePage.id);
      return document.getElementById(containerId) || document.body;
    } else {
      return document.body;
    }
  }

  isolateStyle(tab?: MicroPageConfig) {
    tab = tab || this.getActivePage();
    if (!tab) {
      return;
    }
    this.get().pages.forEach((item) => {
      this.isolateTabStyle(item, tab!.id === item.id);
    });
  }

  private isolateTabStyle(tab: MicroPageConfig, active: boolean) {
    const containerEle = document.getElementById(`${tab.container}`);
    const styleEles = Array.from(containerEle?.querySelectorAll('style') || []);
    const linkEles = Array.from(containerEle?.querySelectorAll('link[rel="stylesheet"]') || []);
    if (active) {
      [...styleEles, ...linkEles].forEach((ele) => {
        if ((ele as any).type === 'text') {
          (ele as any).type = 'text/css';
        }
      });
    } else {
      styleEles.forEach((ele) => {
        // * svelte-jsonEditor的样式不隔离
        if ((ele.id ?? '').includes('svelte')) {
          return;
        }
        if (['', 'text/css'].includes(ele.type)) {
          ele.type = 'text';
        }
      });

      // link类型的css相同app不隔离
      MicroAppStore.pageInsToName;
      const currentTabAppName = MicroAppStore.pageInsToName.get(tab.id);
      const activeTab = this.getActivePage();
      if (!activeTab) {
        return;
      }

      const activeTabAppName = MicroAppStore.pageInsToName.get(activeTab.id);
      if (currentTabAppName && activeTabAppName) {
        const ifSameApp = currentTabAppName === activeTabAppName;
        if (ifSameApp) {
          linkEles.forEach((ele: any) => {
            if (ele.type === 'text') {
              ele.type = 'text/css';
            }
          });
        } else {
          linkEles.forEach((ele: any) => {
            if (['', 'text/css'].includes(ele.type)) {
              ele.type = 'text';
            }
          });
        }
      }
    }
  }

  updateLoaded(page: MicroPageConfig) {
    this.set((state) => {
      const p = state.pages.find((item) => item.id === page.id);
      if (p) {
        p.loaded = true;
      }
    });
  }
}

export const useMicroAppStore = Store.create(MicroAppStore);

(window as any).useMicroAppStore = useMicroAppStore;
